import React from "react";
import "./news-preloader.scss";
import image from "./emty_img_news.png";

const NewsPreloader = () => {
	const renderItem = () => (
		<div className="col-xs-12 col-sm-6 col-md-3">
			<div className="news-preloader__item">
				<img src={image} alt="" className="news-preloader__item__pic" />
				<div className="news-preloader__item__date" />
				<div className="news-preloader__item__text line_1" />
				<div className="news-preloader__item__text line_2" />
			</div>
		</div>
	);

	return (
		<section className="news-preloader">
			<section className="hidden-xs hidden-sm">
				{renderItem()}
				{renderItem()}
				{renderItem()}
				{renderItem()}
			</section>
			<section className="hidden-md">
				{renderItem()}
				{renderItem()}
			</section>
		</section>
	);
};

export default NewsPreloader;
