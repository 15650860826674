import React from "react";
import { Briefly, QuickActions, News } from "@page-contents/index";

export default () => (
	<>
		<Briefly />
		<QuickActions />
		<News />
	</>
);
