import "./quick-actions.scss";
import React from "react";
import Link from "gatsby-link";
import OnlineServicesButton from "@components/online-services-button";

const content = [
	{ name: "Тарифы и стоимость", to: "/services/tariffs/" },
	{ name: "Где пополнить", to: "/where-to-refill/" },
	{ name: "Где приобрести", to: "/where-to-buy/" },
	{ name: "Как пользоваться", to: "/instruction/" }
];

const QuickActions = () => (
	<>
		<section className="button-main-page-refill-card-wrapper hidden-sm hidden-md">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12">
						<OnlineServicesButton
							className={"button-main-page-refill-card"}
							buttonClassName={"button-main-page-refill-card"}
						/>
					</div>
				</div>
			</div>
		</section>
		<section className="quick-actions">
			<div className="grid-container">
				<div className="row">
					{content.map((item, index) => (
						<div className="col-xs-12 col-sm-6 col-md-3" key={index}>
							<Link to={item.to || "/"} className="quick-actions__button-item">
								<span className="quick-actions__counter-ball">{index + 1}</span>
								{item.name}
							</Link>
						</div>
					))}
				</div>
			</div>
		</section>
	</>
);

export default QuickActions;
