import "./news-list.scss";
import React, { useState, useEffect } from "react";
import NewsListItem from "../../page-contents/index/news-list-item";
import { Link } from "gatsby";
import { cms as axios } from "@core/axios";
import { cmsServer } from "@constants";
import NewsPreloader from "./news-preloader";

const News = () => {
	const [data, setData] = useState([]);
	const [count, setCount] = useState(0);

	const getArticles = async () => {
		const responseNews = await axios("/news?_sort=date:DESC&_limit=4");
		const responseNewsCount = await axios("/news/count");
		setData(responseNews.data);
		setCount(responseNewsCount.data);
	};

	useEffect(() => {
		getArticles();
	}, []);

	return (
		<section className="grid-container news-list">
			<div className="row">
				<div className="col-xs-12 col-sm-12 col-md-12 news-list__top">
					<h2 className="news-list__header">Новости</h2>
					{count > 4 && (
						<Link className="link-l news-list__all" to="/about/news">
							Все новости
						</Link>
					)}
				</div>
			</div>
			<div className="row">
				{data && data.length ? (
					data.map((article, index) => {
						const { _id, date, title, image } = article;
						const pic =
							image && image.url ? `${cmsServer}${image.url}` : undefined;
						return (
							<div
								className="col-xs-12 col-sm-6 col-md-3 news-list__item-wrapper"
								key={index}
							>
								<NewsListItem
									pic={pic}
									text={title}
									path={`/about/news/${_id}`}
									publishedAt={date}
									alt="Изображение"
									className="news-list__item"
								/>
							</div>
						);
					})
				) : (
					<NewsPreloader />
				)}
			</div>
		</section>
	);
};

export default News;
